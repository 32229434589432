import React from "react";

// Components
import Template from "../components/shared/Template";
import { graphql } from "gatsby";
import SEO from "../components/shared/SEO";

const Main = ({ data }) => {
  return (
    <>
      <SEO content={{ title: "NPRZ.IO", excerpt: "Welcome to NPRZ.IO" }} />
      <Template data={data.allMdx.edges[0].node} useSEO={false} />
    </>
  );
};

export const query = graphql`
  query HomepageQuery {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }, limit: 1) {
      edges {
        node {
          body
          frontmatter {
            title
            excerpt
          }
        }
      }
    }
  }
`;

export default Main;
